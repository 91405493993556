<template>
  <div class="pageview">
    <!-- banner切换 -->
    <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><img
          style="height: 17vh"
          :src="require('../../assets/partymember/wuxing01.png')"
      /></van-swipe-item>
      <!-- <van-swipe-item><img :src="require('../../assets/partymember/dangjian.jpg')" /></van-swipe-item>
      <van-swipe-item><img :src="require('../../assets/partymember/etuanjian.jpg')" /></van-swipe-item> -->
    </van-swipe>
    <!-- <div style="margin: 10px auto;border-radius: 5px;width: 95%;">
      <div style="border-radius: 8px;background: #ffff;"> -->
    <div
      style="
        border-radius: 8px;
        background: #ffff;
        padding: 3px;
        margin-top: 1.5vh;
      "
    >
      <van-grid
        class="wktype"
        :border="false"
        :column-num="5"
        style="margin-top: 0vh"
      >
        <!-- @click="gotoUrl('/fivestar/zhibu')" -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/zhibu')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/zhibu.png')"
          ></van-image>
          <span class="wktext">支部过硬星</span>
        </van-grid-item>
        <!-- @click="gotoUrl('/fivestar/gongjian')"  -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/gongjian')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/gongjian.png')"
          ></van-image>
          <span class="wktext">共建共享星</span>
        </van-grid-item>
        <!--  @click="gotoUrl('/fivestar/fazhi')" -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/fazhi')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/fazhi.png')"
          ></van-image>
          <span class="wktext">平安法治星</span>
        </van-grid-item>
        <!--  @click="gotoUrl('/fivestar/xingfu')"  -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/xingfu')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/xingfu.png')"
          ></van-image>
          <span class="wktext">幸福和谐星</span>
        </van-grid-item>
        <!-- @click="gotoUrl('/fivestar/xingye')"  -->
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/xingye')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/yiye.png')"
          ></van-image>
          <span class="wktext">宜业兴业星</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- </div>
    </div> -->
    <div style="margin: 10px auto; width: 100%">
      <img :src="require('../../assets/banner.png')" width="100%" />
      <!-- @click="gotoUrl('/fivestar/integral')" -->
    </div>

    <div class="contentmsg">
      <van-sticky>
        <van-tabs
          @click="onClickOnlyOne"
          color="#f63e3b"
          title-active-color="#f63e3b"
        >
          <van-tab name="全部" title="全部"> </van-tab>
          <van-tab
            v-for="item in CateList"
            :key="item.AKId"
            :name="item.AKId"
            :title="item.Name"
          >
          </van-tab>
        </van-tabs>
      </van-sticky>
      <!-- <div class="contentA">
        <img src="../../assets/五星.png" alt="" width="15px" />

        <span>五星活动</span>
        <img
          class="moreImg"
          @click="gotoUrl('/partymember/consult')"
          :src="require('../../assets/partymember/more.png')"
        />

        <span class="moreCount" @click="gotoUrl('/partymember/consult')"
          >更多</span
        >
      </div> -->
      <div class="information">
        <div class="center" v-for="item in List" :key="item.index">
          <!-- <router-link :to="'/fivestar/detail/' + item.RIId"> -->
          <div
            class="Content"
            style="
              border-bottom: 1px solid rgb(245 243 243);
              position: relative;
            "
            @click="gotoUrl('/fivestar/wuXingXq/' + item.AId + '-1')"
          >
            <div class="ContentB">
              <div
                class="centerTitle van-multi-ellipsis--l2"
                style="font-size: 16px"
              >
                {{ item.Title }}
              </div>
              <span
                class="centertime van-multi-ellipsis--l2"
                style="margin: 5px 0px; font-size: 13px; height: 26px"
                >{{ item.Summary }}</span
              >
              <span
                class="centertime"
                style="color: #999999; position: absolute; bottom: 12px"
                >{{ item.IssTime }}
              </span>
            </div>
            <div
              style="display: inline-block; width: 33%; height: 95px"
              class="ContentA"
              ref="getHeight"
            >
              <img v-if="item.AImg" :src="item.AImg" alt="" />
            </div>
          </div>
          <!-- </router-link> -->
        </div>
        <van-empty description="暂无通知" v-if="!List.length" />
        <!-- 公众号文章 -->
        <!-- <div class="center"
          v-for="item in List"
          :key="item.index">
          <router-link :to="'/fivestar/publicActicle/detail/' + item.ArticleId">
            <div class="Content"
              style="border-bottom: 1px solid rgb(245 243 243);">
              <div class="ContentB">
                <div class="centerTitle van-ellipsis"
                  style="font-size:16px">{{ item.Title }}</div>
                <span class="centertime van-multi-ellipsis--l2"
                  style="margin:5px 0px;font-size: 13px;">{{ item.Digest }}</span>
                <span class="centertime"
                  style="color:#999999;">{{ item.IssTime }}</span>
              </div>
              <div style="display: inline-block;width: 33%;"
                class="ContentA"
                ref="getHeight">
                <img v-if="item.ThumbUrl"
                  :src="item.ThumbUrl"
                  alt="" />
              </div>
            </div>
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { WxGetAKindList, WxGetRecruitActivityPage } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data() {
    return {
      List: [],
      CateList: [],
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    this.getPartyInfoList();
    this.getInfoKindInfoPage();
  },
  methods: {
    gotoXQ(row) {
      console.log(row);
      this.$router.push({
        name: "wuXingXq",
        query: { Id: row.RIId, add: 1 },
      });
    },
    // 获取资讯分类
    async getInfoKindInfoPage() {
      const res = await WxGetAKindList({ cate: 2 });
      console.log(res, "res");
      this.CateList = res.data.data;
      console.log(this.CateList, "this.CateList");
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      this.getPartyInfoList(name);
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 通知消息
    getPartyInfoList(val) {
      WxGetRecruitActivityPage({
        page: 1,
        limit: 10,
        akCate: 2,
        akId: val,
      }).then((res) => {
        this.List = res.data.data;
        for (var i = 0; i < this.List.length; i++) {
          var item = this.List[i].IssTime;
          item = this.dataFormat(item);
          this.List[i].IssTime = item;
        }
      });
    },
    // 获取公众号文章
    // getWxArticlePage () {
    //   WxGetWxArticlePage({ page: 1, limit: 15 }).then((res) => {
    //     this.List = res.data.data;
    //     for (var i = 0; i < this.List.length; i++) {
    //       var item = this.List[i].IssTime;
    //       // item = this.dataFormat(item);
    //       // this.List[i].IssTime = item;
    //       if (this.List[i].ThumbUrl == "") {
    //         this.List[i].ThumbUrl =
    //           "http://lccgzapi.zhsq.cloud//upload/images/2021/11/b6b3ab86-df3.png";
    //       }
    //     }
    //   });
    // },
    // 党务
    dangwu: function () {
      var url =
        "https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB";
      window.localtion.href = url;
      // window.open('https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB');
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>

<style scoped>
.ContentB {
  display: inline-block;
  width: 50% !important;
  margin-right: 6%;
  color: #666666 !important;
  margin-left: 5% !important;
}
.djlink {
  margin-top: 1.5vh;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  font-size: 0;
}
.contentA {
  width: 100%;
  overflow: hidden;
  line-height: 30px;
  padding: 2vh 1.5vh 0vh 1.5vh;
  box-sizing: border-box;
}
.contentA img {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 7px;
}
.contentA span {
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  line-height: 30px;
}
.contentA .newsmore {
  float: right;
  color: #777;
  font-size: 14px;
  line-height: 30px;
}
.contentA .blue {
  color: #477fff;
}
.pageview {
  background: #f6f6f6;
  min-height: 100%;
  padding: 1vh;
  box-sizing: border-box;
}
.homebanner img {
  width: 100%;
  height: 27vh;
  vertical-align: top;
}
.wktype {
  padding: 0;
  margin-top: 1.5vh;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 8px;
  font-size: 12px;
}
.contentmsg {
  background: #fff;
  margin-top: 1.5vh;
  border-radius: 8px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 5px 10px 0px;
}
/deep/.wktype .van-grid-item__content {
  padding: 20px 3px;
}
.moreCount {
  color: #999999 !important;
  float: right !important;
  margin-right: 7px !important;
  font-size: 14px !important;
  font-style: initial !important;
}
.moreImg {
  float: right !important;
  width: 8px !important;
  height: 12px !important;
  margin-top: 9px !important;
}

.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.information {
  width: 95%;
  margin: auto;
}
.borderClass {
  padding: 0;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
}
.borderClass .Content {
  padding: 8px;
  margin-top: 10px;
}
/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}
</style>